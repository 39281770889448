import * as React from "react";
import { memo, SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={20}
    height={20}
    {...props}
  >
    <path d="M12 3a1 1 0 0 0-1 1 1 1 0 0 0 .418.814L8.346 9.115 2.947 7.316A1 1 0 0 0 3 7a1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 .217-.025L4 16v4h16v-4l1.783-8.023A1 1 0 0 0 22 8a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 .053.316l-5.399 1.8-3.074-4.303A1 1 0 0 0 13 4a1 1 0 0 0-1-1zm0 4.441 2.025 2.836.86 1.203 1.4-.466 2.996-.998L18.396 14H5.604l-.885-3.986 2.994.998 1.4.467.86-1.202L12 7.441zM6 16h12v2H6v-2z" />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
