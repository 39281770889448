import * as React from "react";
import { memo, SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={"#52C41A"}
    viewBox="0 0 24 24"
    width={20}
    height={20}
    {...props}
  >
    <path d="m16.639 1.904-.57.117s-2.177.452-4.749 1.508C8.75 4.586 5.713 6.23 3.953 8.863l-.015.022-.014.021C3.117 10.262 3 11.564 3 12.562c0 1.908.897 3.521 2.057 4.635.493.475 1.037.85 1.6 1.15a13.374 13.374 0 0 1-3.028 1.725l.742 1.856c5.375-2.15 9.275-7.4 10.594-12.7l-1.942-.482c-.726 2.92-2.397 5.874-4.67 8.137a4.845 4.845 0 0 1-1.91-1.127C5.603 14.948 5 13.842 5 12.562c0-.858.074-1.655.637-2.61 1.37-2.034 4.061-3.595 6.443-4.573 2.068-.85 3.358-1.11 3.8-1.21 2.16 2.716 3.09 6.052 3.118 8.222.03 2.248-.551 3.875-1.416 4.941-.862 1.062-2.01 1.608-3.34 1.664-.595-.007-1.273.02-2.26-.738l-1.218 1.586c1.462 1.123 2.946 1.147 3.49 1.156h.055a6.492 6.492 0 0 0 4.826-2.408c1.215-1.499 1.898-3.618 1.863-6.227-.037-2.819-1.138-6.803-3.975-10.025l-.384-.436z" />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
