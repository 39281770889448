import * as React from "react";
import { memo, SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={20}
    height={20}
    {...props}
  >
    <path d="M17.5 22h-11V9l7-7 1.3 1.3c.1 0 .2.3.3.5s.1.4.1.6v.4l-1.1 4.4h6.5c.5 0 1 .2 1.4.6s.6.9.6 1.4v2.4c0 .2 0 .3-.1.4l-3 7.1c-.2.3-.4.6-.8.9h-1.2Zm-9-2h9l3-7v-2h-9l1.4-5.5-4.4 4.4v10.2Zm0-10.2V20 9.8Zm-2-.8v2h-3v9h3v2h-5V9h5Z" />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
